.Programs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-header{
display: flex;
gap: 5rem;
font-weight: bold;
font-size: 3rem;
color: white;
text-transform: uppercase;
font-style: italic;
justify-content: center;
}
.program-categories{
display: flex;
gap: 1rem;
}
.category {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: gray;
    color: white;
    padding: 2rem;
    justify-content: space-between;
    border-radius: 4px;
}
.category>:nth-child(1) {
    width: 2rem;
    height: 2rem;
    fill: orange;
}

.category>:nth-child(2) {
 font-size: 1rem;
 font-weight: bold;
  
}
.category>:nth-child(3) {
  font-size: 0.9rem;
  line-height: 25px;
  
}
.join-now {
    display: flex;
    align-items: center;
    gap: 3rem;
}
.join-now>img{
   width: 1.3rem;
}
.category:hover{
    background: var(--planCard);
    cursor: pointer;
    
}
.category>:nth-child(1):hover {
    fill: white;
}
@media screen and (max-width: 768px){
    .programs-header {
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .program-categories{
        flex-direction: column;
    }
}